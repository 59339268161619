<template>
  <ValidationObserver tag="div" ref="validationObserver">
    <div class="editblock">
      <content-block-group>
        <content-block>
          <div slot="instructions">
            <h5 class="captiontitle">Booking Form Configs</h5>
            <p class="captiondesc">{{ $t(' Customization options for the booking form.') }}</p>
          </div>
          <div class="body-content">
            <template v-if="localExperience">
              <UISwitch
                v-model="localExperience.bookingBirthdateRequired"
                :title="$t('Birth date is mandatory')"
                :instructions="$t('When enabled the birth date is mandatory for the booking form, to make sure birth date is entered for the player.')"
              ></UISwitch>
            </template>
          </div>
        </content-block>
        <content-block>
          <div slot="instructions">
            <h5 class="captiontitle">Logo</h5>
            <p class="captiondesc">{{ $t('Upload your logo.') }}</p>
          </div>
          <div class="logo-upload">
            <div class="w-40-m">
              <div class="imagegrid onecolumn">
                <template v-if="localExperience">
                  <UIRadioThumbnail
                    class="coverimgselected dib w-100 h-100"
                    v-if="localExperience.imageUrl"
                    name="image"
                    :value="localExperience.imageUrl"
                    v-model="localExperience.imageUrl"
                    :style="{ backgroundImage: `url(${localExperience.imageUrl})` }"
                  />
                </template>
                <div>
                  <UIFileUpload @change="logoChange" />
                  <small class="f-instructions"></small>
                </div>
              </div>
            </div>
          </div>
        </content-block>
      </content-block-group>
    </div>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import CommonMixin from './CommonMixin';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import { ValidationObserver } from 'vee-validate';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';
import UIRadioThumbnail from '@/core/shared/components/ui/UIRadioThumbnail';
import FileHelper from '@/core/shared/helpers/FileHelper';

@Component({
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ContentBlockGroup,
    UISwitch,
    ValidationObserver,
    UIFileUpload,
    UIRadioThumbnail,
  },
})
export default class ExperiencesShowBookingConfig extends Vue {
  async logoChange ([file]) {
    const imageUrl = await FileHelper.encodeImage(file);
    this.$set(this.localExperience, '_blob', file);
    this.$set(this.localExperience, 'blobFieldName', 'image');
    this.$set(this.localExperience, 'imageUrl', imageUrl);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.logo-upload {
  & .imagegrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);
    /* grid-auto-rows:  */
    grid-auto-rows: minmax(120px, max-content);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .onecolumn {
    grid-template-columns: 1fr;
  }
}
</style>
